import React from 'react';
import { DatadogLogger } from '@attentive/acore-utils';
import { SectionName } from '../constants/routes';

const MFE_NAME = 'settings-ui';

const SlotNameContext = React.createContext<SectionName | undefined>(undefined);
const SlotNameProvider = SlotNameContext.Provider;

function useSlotName() {
  const context = React.useContext(SlotNameContext);
  if (context === undefined) {
    // TODO(bfcm): Replace this with a thrown error after code freeze. If a
    // component uses this outside of a provider we can gracefully handle it
    // and provide the same behavior (no `slotName` on the error) as prior to
    // this change. During code freeze we don't want to risk throwing a new
    // error and breaking a page. After bfcm though we want to enforce proper
    // usage of this hook by throwing errors.
    console.error('useSlotName must be used within a SlotNameProvider.');
  }
  return context as SectionName;
}

interface SlotNameDecoratorProps {
  slotName?: SectionName;
}

interface StorybookContext {
  args?: SlotNameDecoratorProps;
  parameters?: SlotNameDecoratorProps;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SlotNameDecorator = (story: any, context: StorybookContext) => {
  const { args, parameters } = context;
  const slotName = args?.slotName || parameters?.slotName || SectionName.LEGAL;

  return <SlotNameProvider value={slotName}>{story()}</SlotNameProvider>;
};

const { logError } = new DatadogLogger({
  projectName: MFE_NAME,
});

export { logError, SlotNameDecorator, SlotNameProvider, useSlotName, SectionName as SlotName };
